import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const cissPlugin = createPlugin({
  id: 'ciss',
  routes: {
    root: rootRouteRef,
  },
});

export const CissPage = cissPlugin.provide(
  createRoutableExtension({
    name: 'CissPage',
    component: () =>
      import('./components/CissPage').then(m => m.CissPage),
    mountPoint: rootRouteRef,
  }),
);
