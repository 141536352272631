import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const catalogInfoViewerPlugin = createPlugin({
  id: 'catalog-info-viewer',
  routes: {
    root: rootRouteRef,
  },
});

export const CatalogInfoViewerPage = catalogInfoViewerPlugin.provide(
  createRoutableExtension({
    name: 'CatalogInfoViewerPage',
    component: () =>
      import('./components/ViewerPage').then(m => m.ViewerPage),
    mountPoint: rootRouteRef,
  }),
);
